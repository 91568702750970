import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './@core/utils/AuthGuard';
import { PointClientsComponent } from './pages/data-management/point-clients/point-clients.component';
import { TechniciensComponent } from './pages/data-management/techniciens/techniciens.component';
import { VehiculesComponent } from './pages/data-management/vehicules/vehicules.component';
import { HistoryDateComponent } from './pages/history/history-date/history-date.component';
import { HistoryLiftComponent } from './pages/history/history-lift/history-lift.component';
import { HistoryUserComponent } from './pages/history/history-user/history-user.component';
import { HomeComponent } from './pages/home/home.component';
import { CuratifMaintenanceComponent } from './pages/maintenance/curatif-maintenance/curatif-maintenance.component';
import { PreventiveMaintenanceComponent } from './pages/maintenance/preventive-maintenance/preventive-maintenance.component';
import { PagesComponent } from './pages/pages.component';
import { SigninComponent } from './signin/signin.component';
import {LiftsComponent} from "./pages/data-management/lifts/lifts.component";

const routes: Routes = [
  {
    path: 'signin', component: SigninComponent
  },
  {
    path: 'pages', component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/pages/home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'vehicules', component: VehiculesComponent },
      { path: 'lifts', component: LiftsComponent },
      { path: 'techniciens', component: TechniciensComponent },
      { path: 'pointClients', component: PointClientsComponent },
      { path: 'preventive', component: PreventiveMaintenanceComponent },
      { path: 'curatif', component: CuratifMaintenanceComponent },
      { path: 'historyDate', component: HistoryDateComponent },
      { path: 'historyUser', component: HistoryUserComponent },
      { path: 'historyLift', component: HistoryLiftComponent }
    ]
  },

  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: '**', redirectTo: 'signin' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
