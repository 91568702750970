import { Component, OnInit } from '@angular/core';
import {LocalDataSource} from "ng2-smart-table";
import {DataManagementService} from "../data-management.service";
import {Lift} from "../../../data/data";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ngx-lifts',
  templateUrl: './lifts.component.html',
  styleUrls: ['./lifts.component.scss']
})
export class LiftsComponent implements OnInit {

  constructor(
    public dataManagementService: DataManagementService,
    private tosatrService: ToastrService,
  ) {
    this.prepareNg2SmartTable();
  }

  status: any[] = [{
    value: "ARRET", // Use codeClient as the value
    title: "ARRET",       // Use name as the title
  }
  ,{
    value: "Sous Contrat", // Use codeClient as the value
    title: "Sous Contrat",       // Use name as the title
  }]
  settings: any;
  ngOnInit(): void {
    this.loadPoiClient();
  }
  poiClient: any[] = []
  loadLifts() {
    if (this.dataManagementService.lifts.length === 0) {
    this.dataManagementService.findAllLifts().subscribe(lifts => {
      this.dataManagementService.lifts = lifts;
      console.log("this is lifts", this.dataManagementService.lifts);
      this.source.load(this.dataManagementService.lifts);
    })
  } else {
      this.source.load(this.dataManagementService.lifts);
    }
  }

  loadPoiClient() {
    this.loadLifts();
    this.dataManagementService.findAllPointClients().subscribe(pointClients => {
      this.dataManagementService.pointClients = pointClients;
      this.poiClient = pointClients.map(client => ({
        value: client.codeClient, // Use codeClient as the value
        title: client.name,       // Use name as the title
      }));
      this.prepareNg2SmartTable();
      console.log("poi client",this.poiClient);
      }
    );
  }

  prepareNg2SmartTable() {
  this.settings = {
    pager: {
      perPage: 10,
    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        type: 'string',
        filter: true,
        addable: false,
        editable: false
      },
      name: {
        title: 'Name',
        type: 'string',
        filter: true,
        addable: true,
        editable: true
      },
      numCasne: {
        title: 'Num Casne',
        type: 'number',
        filter: true,
        addable: true,
        editable: true
      },
      adress: {
        title: 'Adresse',
        type: 'string',
        filter: true,
        addable: true,
        editable: true
      },
      codeClient:{
        title: 'Client',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          const client = this.dataManagementService.pointClients.find(client => client.codeClient === cell);
          console.log("this is client", client);
          return client ? client.name : 'Inconnu'; // Fallback to 'Inconnu' if no match is found
        },
        editable: true,
        editor: {
          type: 'list',
          config: {
            selectText: 'Category',
            list: this.poiClient,
          },
        },
      },
      statContrat: {
        title: 'Status Contrat',
        type: 'string',
        editor: {
          type: 'list',
          config: {
            selectText: 'Category',
            list: this.status,
          },
        },
        filter: true,
        addable: true,
        editable: true
      },
    }
  };
  }
  source: LocalDataSource = new LocalDataSource();



  onDeleteConfirm(event): void {
    if (window.confirm(`Are you sure you want to delete ${event.data.name}?`)) {
      this.dataManagementService.deleteLifts(event.data.id).subscribe(
        res => {
          console.log(res);
          event.confirm.resolve();
          this.tosatrService.success("Ascenseurs supprimer avec succees");
          this.dataManagementService.loadLifts();
        }
      );
    } else {
      event.confirm.reject();
    }
  }

  onCreateConfirm(event): void {
    if (window.confirm(`Are you sure you want to add ${event.newData.name}?`)) {
      console.log("this is new data", event.newData);
      let lift: Lift = new Lift();
      lift.numCasne = event.newData.numCasne;
      lift.name = event.newData.name;
      lift.adress = event.newData.adress;
      lift.longtitude = event.newData.longtitude;
      lift.codeClient = event.newData.codeClient;
      lift.statContrat = event.newData.statContrat;
      lift.etat = event.newData.statContrat.toLowerCase() !== "arret";

      this.dataManagementService.addLifts(lift).subscribe(liftAdded => {
        event.confirm.resolve();
        this.tosatrService.success("Ascenseurs ajouté avec succees");
      });

      console.log("this is lift added", lift);
    }

  }

  onSaveConfirm(event): void {
    if (window.confirm(`Are you sure you want to edit ${event.newData.name}?`)) {
        // console.log("this is new data", event.newData);
        let lift: Lift = new Lift();
	lift.id = event.newData.id;
        lift.numCasne = event.newData.numCasne;
        lift.name = event.newData.name;
        lift.adress = event.newData.adress;
        lift.longtitude = event.newData.longtitude;
        lift.codeClient = event.newData.codeClient;
        lift.statContrat = event.newData.statContrat;
        lift.etat = event.newData.statContrat.toLowerCase() !== "arret";

        this.dataManagementService.updateLifts(lift).subscribe(liftAdded => {
          event.confirm.resolve();
          this.tosatrService.success("Ascenseurs edité avec succees");
        });

    } else {
      event.confirm.reject();
    }
  }
}
