
export class User {
    id: number;
    username: string;
    firstname: string;
    lastname: string;
    rawPassword: string;
    phone: number;
    email: string;
    dateBirth: Date;
    vehicule: Vehicule[];
    authorities: Authority[];
    authorityName: string;
}

export class Authority {
    id: number;
    name: string;
    label: string;
    constructor(id, name, label) {
        this.id = id;
        this.name = name;
        this.label = label;

    }
}

export class PointClient {
    id: number;
    name: string;
    codeClient: number;
    address: string;
    ray: number;
    zoomLevel: number;
    imageUri: string;
    coordinate: { lat: number, lng: number };
    type: string;
    decode: any[];
    hash: string;
    nbVisit: number;
    emails: string;
    phone: number;
}

export class PointClientUpdate {
    id: number;
    name: string;
    codeClient: number;
    address: string;
    nbVisit: number;
    emails: string;
    phone: number;
    ville: string;
    dateIntervention: Date;
}

export class Vehicule {
    idVehicule: number;
    matricule: string;
    mark: string;
    circulationAt: Date;


}


export class Lift {
    id: number;
    name: string;
    adress: string;
    latitude: number;
    longtitude: number;
    numCasne: number;
    codeClient: number;
    nb_visit: number;
    statContrat: string;
    etat: boolean;
}

export class TimePlan {
    id: string;
    idTimePlan: number;
    name: string;
    start: Date;
    end: Date;
    background: string;
    about: string;
    recurrenceRule: string;
    userId: number;
    liftId: number;
}

export class MaintenanceDateDto {
    idMaintenance: number;
    date: Date;
    status: number;
    userId: number;
    liftId: number;
    description: string;
    type: string;
}

export class DateIntervalDto {
    startDate: number;
    endDate: number;
}


export class Maintenance {
    idMaintenance: number;
    userId: number;
    description: string;
    liftId: number;
    date: Date;
    status: number;
    id: string;
    name: string;
    start: Date;
    end: Date;
    background: string;
    about: string;
    recurrenceRule: string;
}

export class MaintenancePreventif {
    description: string;
}

export class MaintenanceCuratif {
    description: string;
}
