import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createAuthorizationHeader } from 'src/app/@core/utils/headers';
import { DateIntervalDto, Lift, Maintenance, MaintenanceDateDto, PointClient, User, Vehicule, Authority, PointClientUpdate } from '../../data/data';
import { dns } from 'src/app/global.config';

@Injectable({
  providedIn: 'root'
})
export class DataManagementService {

  users: User[] = [];
  vehicules: Vehicule[] = [];
  pointClients: PointClient[] = [];
  maintenanceDateDtos: MaintenanceDateDto[] = [];
  lifts: Lift[] = [];
  maintenances: Maintenance[] = [];
  selectedPointClient: PointClient;
  authorties: Authority[] = [];
  componentName: any;
  inited: boolean = false;

  eventDetails: EventEmitter<any> = new EventEmitter();
  public DataLoaded$: EventEmitter<any> = new EventEmitter();
  constructor(private _http: HttpClient) {

  }

  init(): boolean {
    if (!this.inited) {
      this.loadUsers();
      this.loadLifts();
      return false;
    }
    return true;
  }

  initAuthorties(): boolean {
    if (!this.inited) {
      this.findAllAuthorties().subscribe(authorties => {
        this.authorties = authorties;
        this.eventDetails.emit();
      })
      return false;
    }
    return true;
  }

  /****************************users*************************** */
  findAllUsers(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'user', { headers: headers });
  }

  addUsers(user: User): Observable<any> {
    let headers = createAuthorizationHeader();
    user.authorities = [];
    switch (user.authorityName) {
      case "Administrateur":
        user.authorities.push(new Authority(1, "ROLE_ADMIN", "Administrateur"));
        break;

      case "Technicien":
        user.authorities.push(new Authority(2, "ROLE_TECHNICIAN", "Technicien"));
        break;

      case "Ouvrier":
        user.authorities.push(new Authority(3, "ROLE_OUVRIER", "Ouvrier"));
        break;

      case "Agent":
        user.authorities.push(new Authority(4, "ROLE_AGENT", "Agent"));
        break;
    }
    return this._http.post(dns + 'user/add', user, { headers: headers });
  }

  updateUsers(user: User): Observable<any> {
    let headers = createAuthorizationHeader();
    user.authorities = [];
    switch (user.authorityName) {
      case "Administrateur":
        user.authorities.push(new Authority(1, "ROLE_ADMIN", "Administrateur"));
        break;

      case "Technicien":
        user.authorities.push(new Authority(2, "ROLE_TECHNICIAN", "Technicien"));
        break;

      case "Ouvrier":
        user.authorities.push(new Authority(3, "ROLE_OUVRIER", "Ouvrier"));
        break;

      case "Agent":
        user.authorities.push(new Authority(4, "ROLE_AGENT", "Agent"));
        break;
    }
    return this._http.put(dns + 'user/update', user, { headers: headers });
  }

  deleteUsers(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'user/' + id, { headers: headers });
  }

  loadUsers() {
    this.findAllUsers().subscribe(us => {
      this.users = us;
    })
  }

  getUserIdByNameUser(userId: number) {
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].id == userId) {
        if (this.users[i].firstname == 'null')
          return this.users[i].lastname;
        else if (this.users[i].lastname == 'null') {
          return this.users[i].firstname;
        } else if (this.users[i].lastname != 'null' || this.users[i].firstname != 'null') {
          return this.users[i].lastname + " " + this.users[i].firstname;
        }
      }
    }
  }


  /*********AUTHORITY *******/
  findAllAuthorties(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'authority', { headers: headers });
  }

  /*********************vehicules********************* */
  findAllVehicules(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'vehicules', { headers: headers });
  }

  addVehicules(vehicules: Vehicule): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'vehicules/add', vehicules, { headers: headers });
  }

  updateVehicules(vehicules: Vehicule): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.put(dns + 'vehicules/update', vehicules, { headers: headers });
  }

  deleteVehicules(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'vehicules/' + id, { headers: headers });
  }


  loadVehicules() {
    this.findAllVehicules().subscribe(veh => {
      this.vehicules = veh;
    })
  }


  /**********************lifts******************* */
  findAllLifts(): Observable<Lift[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Lift[]>(dns + 'lift', { headers: headers });
  }

  addLifts(lift: Lift): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'lift/add', lift, { headers: headers });
  }

  updateLifts(lift: Lift): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.put(dns + 'lift/update', lift, { headers: headers });
  }

  deleteLifts(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'lift/' + id, { headers: headers });
  }


  loadLifts() {
    this.findAllLifts().subscribe(lif => {
      this.lifts = lif;
    })
  }


  getLiftById(liftId: number): Lift {
    for (let i = 0; i < this.lifts.length; i++) {
      if (this.lifts[i].codeClient == liftId) {
        //if (this.lifts[i].name == 'null')
          return this.lifts[i];
      }
    }
  }
  formatLiftName(lift: Lift) {
    return lift.codeClient+"-"+lift.numCasne+"-"+lift.name
  }
  /********************clients***************** */
  /*********************vehicules********************* */
  findAllPointClients(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'pointclient', { headers: headers });
  }

  addPointClients(pointclient: PointClient): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'pointclient/add', pointclient, { headers: headers });
  }

  updatePointClients(pointclient: PointClientUpdate): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.put(dns + 'pointclient/update', pointclient, { headers: headers });
  }

  deletePointClients(id: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'pointclient/' + id, { headers: headers });
  }

  /*********************maintenances********************* */
  findAllMaintenancesByDate(dateIntervalDto: DateIntervalDto): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'maintenance/bydate', dateIntervalDto, { headers: headers });
  }

  findAllMaintenancesByUser(userId: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'maintenance/byuser', userId, { headers: headers });
  }

  findAllMaintenancesByLift(liftId: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'maintenance/bylift', liftId, { headers: headers });
  }

  /*********************maintenances prventif********************* */
  findAllMaintenancePreventifs(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'maintenance/allPreventif', { headers: headers });
  }

  getMaintenancesByUserDatePreventifs(idUser: number, dateInterval: DateIntervalDto): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'maintenance/byUserDatePreventif/' + idUser, dateInterval, { headers: headers });
  }

  addMaintenancePreventif(maintenance: Maintenance): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'maintenance/addpreventif', maintenance, { headers: headers });
  }
  updateMaintenancePreventif(maintenance: Maintenance): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'maintenance/updatepreventif', maintenance, { headers: headers });
  }


  deleteMaintenancePreventif(idPreventif: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'maintenance/deletepreventif/' + idPreventif, { headers: headers });
  }

    /*********************maintenances curatif********************* */
  findAllMaintenanceCuratifs(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'maintenance/allCuratif', { headers: headers });
  }

  getMaintenancesByUserDateCuratifs(idUser: number, dateInterval: DateIntervalDto): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'maintenance/byUserDateCuratif/' + idUser, dateInterval, { headers: headers });
  }

  addMaintenanceCuratif(maintenance: Maintenance): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'maintenance/addcuratif', maintenance, { headers: headers });
  }

  updateMaintenanceCuratif(maintenance: Maintenance): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'maintenance/updatecuratif', maintenance, { headers: headers });
  }


  deleteMaintenanceCuratif(idCuratif: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.delete(dns + 'maintenance/deletecuratif/' + idCuratif, { headers: headers });
  }



}
